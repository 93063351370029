//@flow
import * as React from "react";
import { create, all, evaluate } from "mathjs";
import JumpLogic from "../JumpLogic";
import {
  type resultType,
  type calculatorConditionType,
  type calculatorConditionRuleType,
  type jointLogicType
} from "../../../../../FlowTypes/thankyouPageCalculatorConfigType";
import formConfiguration, { QuestionTypeEnum } from "../../../../QuestionTypes";
import {
  UICoreListItemTypeEnum,
  type UICoreListItemType
} from "../../../../../FlowTypes/UICoreTypes/UICoreListType";
import {
  getThankyouPages,
  getThankyouPagesDisplayLogic,
  getAllQuestions
} from "../../../States/selectors";

import { connect } from "react-redux";
import {
  type thankyouPageType,
  type questionIDType
} from "../../../../WispformTypings";
import {
  update_thankyou_page_display_logic,
  add_thankyou_page,
  update_calculator_condition,
  saveToBackend
} from "../../../States/actions";
import { type dropdownFooterButtonType } from "../../../../../Component/UICore/UICoreDropdown";
import {
  UICoreBox,
  UICoreButton,
  UICoreText,
  UICoreModal,
  UICoreSection,
  UICoreInput,
  UICoreFlyOut,
  UICoreList,
  UICoreDropdown
} from "../../../../../Component/UICore";
import UICoreTagInput from "../../../../../Component/UICore/UICoreTagInput";
import * as InputManager from "../../../../../Component/UICore/UICoreTagInput/InputManager";
import {
  isNullOrUndefined,
  nonNullOrThrow,
  toArray,
  isNonEmptyArray
} from "../../../../../Library/Util";
import { Scrollbars } from "react-custom-scrollbars";
import { getTagInputOptionsForCalculatorFormula } from "../../../../../Library/AnswerPiping";
import QuestionType, {
  type questionType
} from "../../../../../Pages/QuestionTypes";
import "./style.css";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import {
  isTargetFormScore,
  getFormScoreTargetID
} from "../../../../../Library/JumpLogic";
import Comperators from "../../../../../Middlewares/LogicJumpMiddleware/comperators";
import {
  type comperatorNameTypes,
  type comperatorType,
  targetSelector
} from "../../../../../FlowTypes/jumpLogicComperatorTypes";

const arrayOffSet = 1;

// $FlowFixMe
const questionTypes = new formConfiguration().types;

const conditionOperators = [
  { value: "add", display: "add" },
  { value: "subtract", display: "subtract" },
  { value: "multiply", display: "multiply" },
  { value: "divide", display: "divide" }
];

type Props = {|
  ...InjectedProps,
  result: resultType,
  questions: Array<questionType>,
  calculatorConfig: any,
  className: string,
  styles: any,
  updateResult: (string, any) => void,
  deleteResult: string => void,
  updateCalculatorCondition: (
    string,
    string,
    Array<calculatorConditionType>
  ) => void,
  saveToBackend: () => mixed
|};
type State = {|
  showModal: boolean,
  formulaInput: string,
  showFlyOut: boolean,
  condition: any,
  formula: ?string,
  type: string,
  id: string,
  showFormulaError: boolean
|};

class CalculatorConfigResult extends React.Component<Props, State> {
  _flyOutNode: ?HTMLElement = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      type: this._isFormula() ? "formula" : "conditional",
      formula: this._parentFormula() ? this._parentFormula() : "",
      id: this.props.result && this.props.result.id ? this.props.result.id : "",
      showFormulaError: false,
      condition: null,
      formulaInput: "",
      showFlyOut: false
    };
  }

  _parentFormula = () => {
    if (this.props.result && this.props.result.formula)
      return this.props.result.formula;
    else {
      return null;
    }
  };

  _isFormula = () => {
    if (
      this.props.result &&
      this.props.result.type &&
      this.props.result.type === "conditional"
    ) {
      return false;
    } else {
      return true;
    }
  };

  _renderCalculatorResultAndButton = () => {
    const result = this.props.calculatorConfig.results.find(
      x => x.name === this.props.result.name
    );
    if (result) {
      const hasUserDefinedLogic = result.type;
      if (hasUserDefinedLogic) {
        return (
          <UICoreBox
            marginBottom="sm"
            justifyContent="between"
            direction="row"
            // alignment="center"
          >
            <UICoreText
              color="black"
              size="xs"
              overflow="ellipsis"
              dangerous_style={{ whiteSpace: "nowrap" }}
            >
              {" "}
              {this.props.result.name}
            </UICoreText>
            <UICoreBox onClick={_ => this.setState({ showModal: true })}>
              <UICoreButton key={0} color="blue" size="sm">
                {" Update "}
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        );
      } else {
        return (
          <UICoreBox
            marginBottom="sm"
            justifyContent="between"
            direction="row"
            // alignment="center"
          >
            <UICoreText
              color="black"
              size="xs"
              overflow="ellipsis"
              dangerous_style={{ whiteSpace: "nowrap" }}
            >
              {this.props.result.name}
            </UICoreText>
            <UICoreBox onClick={_ => this.setState({ showModal: true })}>
              <UICoreButton key={1} color="lightBlue" size="sm">
                {" Set "}
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        );
      }
    }
  };

  _saveVariable = () => {
    if (this.state.type === "conditional") {
      this.props.saveToBackend();
      this.props.updateResult(this.props.result.name, {
        name: this.props.result.name,
        id: this.state.id,
        type: this.state.type,
        formula: this.state.formula,
        condition: this.props.result.condition
      });
      this.setState({ showModal: false });
    } else {
      let sanitizedString = this.state.formula
        ? this.state.formula
            .replace(/\uFEFF/g, "")
            .replace(/\n/g, "")
            .replace(/\s/g, "")
        : "";
      let convertedString =
        InputManager.convertStringRep2HumanRedableStringWithNumber(
          sanitizedString
        )
          .replace(/\uFEFF/g, "")
          .replace(/\n/g, "")
          .replace(/\s/g, "");
      const math1 = create(all, {});
      try {
        let a = math1.evaluate(convertedString);
        this.props.updateResult(this.props.result.name, {
          name: this.props.result.name,
          id: this.state.id,
          type: this.state.type,
          formula: sanitizedString,
          condition: this.props.result.condition
        });
        this.setState({ showModal: false });
      } catch {
        this.props.showNotification(
          "error",
          "Add variable formula failed",
          "Please make sure you have entered a valid formula. If you continue to experience this issue, please reach out to admin@wispform.com"
        );
      }
    }
  };

  _getAllTargetQuestionsCandidate = () => {
    return this._filterOutUnSupportedQuestion(this.props.questions).map(
      question => {
        return {
          value: question.question_id,
          display: this._getDisplayTitle(question.title, question.question_id)
        };
      }
    );
  };

  _filterOutUnSupportedQuestion = (questions: Array<questionType>) => {
    return questions.filter(q => this._isJumpLogicSupportedQuestion(q));
  };

  _isJumpLogicSupportedQuestion = (question: ?questionType) => {
    if (!question) return false;
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return false;
    return isNonEmptyArray(questionConfig.comperators);
  };

  _getQuestionByID = (questionID: questionIDType): ?questionType => {
    return toArray(this.props.questions).find(
      q => q.question_id === questionID
    );
  };

  _getQuestionIndexByID = (questionID: ?questionIDType): number => {
    return this.props.questions.findIndex(q => q.question_id === questionID);
  };

  _getDisplayTitle = (title: ?string, questionID: ?questionIDType): string => {
    return `Q${this._getQuestionIndexByID(questionID) + arrayOffSet}.${String(
      title
    )}`;
  };

  _getQuestionTitle = (question: ?questionType) =>
    question ? question.title : null;

  _getQuestionChoice = (questionID: questionIDType) => {
    return questionID
      ? {
          value: questionID,
          display: this._getDisplayTitle(
            this._getQuestionTitle(this._getQuestionByID(questionID)),
            questionID
          )
        }
      : null;
  };

  _getCondition = () => {
    return this.props.result && this.props.result.condition;
  };

  _getConditionRule = (condition: calculatorConditionType) => {
    if (condition && condition.conditionRule) return condition.conditionRule;
    else return [];
  };

  _getJointLogics = (condition: calculatorConditionType) => {
    if (condition && condition.jointLogics) return condition.jointLogics;
    else return [];
  };

  _updateJointLogic = (
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    jointLogic: jointLogicType,
    questionID: questionIDType
  ) => {
    const currentCondition =
      this._getSpecificConditionForSpecificQuestion(
        questionID,
        conditionItemIndex
      ) || {};

    this._updateCondition(questionID, conditionItemIndex, {
      ...currentCondition,
      jointLogics: [
        ...this._getJointLogics(currentCondition).slice(
          0,
          conditionItemRuleIndex
        ),
        jointLogic,
        ...this._getJointLogics(currentCondition).slice(
          conditionItemRuleIndex + 1
        )
      ]
    });
  };

  _getFirstComperator = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return Comperators.numericMoreThan;
    }
    return toArray(this._getQuestionComperatorsByQuestionID(questionID))[0];
  };

  _getQuestionComperatorsByQuestionID = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return [
        Comperators.numericIs,
        Comperators.numericLessThan,
        Comperators.numericMoreThan
      ];
    }
    const question = this._getQuestionByID(questionID);
    if (!question) return [];
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return [];
    return toArray(questionConfig.comperators);
  };

  _getFirstChoice = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return 0;
    }
    const answerChoices = this._getAnswerChoices(questionID);
    if (answerChoices) {
      return answerChoices[0].value;
    } else {
      return "";
    }
  };

  _getAnswerChoices = (questionID: questionIDType) => {
    const question = this._getQuestionByID(questionID);
    if (!question) return [];
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return [];
    return (
      questionConfig.getAnswerChoices &&
      questionConfig.getAnswerChoices(question)
    );
  };

  _getComperatorChoices = (comperators: Array<comperatorType>) => {
    return comperators.map(comperator => {
      return { display: comperator.displayName, value: comperator.typeName };
    });
  };

  _getAllConditionForSpecificQuestion = (
    questionID
  ): Array<calculatorConditionType> => {
    if (
      this.props.result &&
      this.props.result.condition &&
      this.props.result.condition[questionID]
    ) {
      return this.props.result.condition[questionID];
    } else {
      return [];
    }
  };

  _getSpecificConditionForSpecificQuestion = (
    questionID,
    conditionItemIndex
  ): calculatorConditionType => {
    if (
      this.props.result &&
      this.props.result.condition &&
      this.props.result.condition[questionID] &&
      this.props.result.condition[questionID][conditionItemIndex]
    ) {
      return this.props.result.condition[questionID][conditionItemIndex];
    } else {
      return {
        left: "",
        targetQuestionID: questionID,
        right: "",
        operation: "add",
        jointLogics: [],
        conditionRule: [],
        questionId: questionID
      };
    }
  };

  _getSpecificConditionRuleForSpecificQuestion = (
    questionID,
    conditionItemIndex,
    conditionItemRuleIndex
  ) => {
    return (
      this.props.result &&
      this.props.result.condition &&
      this.props.result.condition[questionID] &&
      this.props.result.condition[questionID][conditionItemIndex] &&
      this.props.result.condition[questionID][conditionItemIndex]
        .conditionRule &&
      this.props.result.condition[questionID][conditionItemIndex].conditionRule[
        conditionItemRuleIndex
      ]
    );
  };

  _addCondition = questionID => {
    const condition = this._getAllConditionForSpecificQuestion(questionID)
      ? this._getAllConditionForSpecificQuestion(questionID)
      : [];
    this.props.updateCalculatorCondition(questionID, this.props.result.id, [
      ...condition,
      {
        questionId: questionID,
        operation: "add",
        left: this.props.result.id,
        right: "10",
        conditionRule: [
          {
            targetQuestionID: questionID,
            comperator: this._getFirstComperator(questionID).typeName,
            targetAnswer: ""
          }
        ],
        jointLogics: []
      }
    ]);
  };

  _deleteCondition = (questionID, conditionItemIndex) => {
    this.props.updateCalculatorCondition(
      questionID,
      this.props.result.id,
      toArray(this._getAllConditionForSpecificQuestion(questionID)).filter(
        (val, index) => index !== conditionItemIndex
      )
    );
  };

  _updateCondition = (
    questionID: questionIDType,
    conditionItemIndex: number,
    conditionItem: calculatorConditionType
  ) => {
    const condition =
      this._getAllConditionForSpecificQuestion(questionID) || [];
    this.props.updateCalculatorCondition(questionID, this.props.result.id, [
      ...condition.slice(0, conditionItemIndex),
      conditionItem,
      ...condition.slice(conditionItemIndex + 1)
    ]);
  };

  _addConditionRule = (conditionItemIndex, conditionRuleIndex, questionID) => {
    const condition =
      this._getAllConditionForSpecificQuestion(questionID) || [];
    const currentCondition =
      this._getSpecificConditionForSpecificQuestion(
        questionID,
        conditionItemIndex
      ) || {};
    const specificConditionRule =
      this._getSpecificConditionRuleForSpecificQuestion(
        questionID,
        conditionItemIndex,
        conditionRuleIndex
      ) || {};
    const targetQuestionID = questionID;
    if (!targetQuestionID) return;
    this._updateCondition(questionID, conditionItemIndex, {
      ...currentCondition,
      conditionRule: [
        ...currentCondition.conditionRule.slice(0, conditionRuleIndex + 1),
        {
          targetQuestionID: targetQuestionID,
          comperator:
            this._getFirstComperator(targetQuestionID) &&
            this._getFirstComperator(targetQuestionID).typeName,
          targetAnswer: this._getFirstChoice(targetQuestionID)
        },
        ...currentCondition.conditionRule.slice(conditionRuleIndex + 1)
      ],
      jointLogics: [
        ...this._getJointLogics(currentCondition).slice(0, conditionRuleIndex),
        "&&",
        ...this._getJointLogics(currentCondition).slice(conditionRuleIndex)
      ]
    });
  };

  _removeConditionRule = (
    conditionItemIndex,
    conditionRuleIndex,
    questionID
  ) => {
    const currentCondition =
      this._getSpecificConditionForSpecificQuestion(
        questionID,
        conditionItemIndex
      ) || {}; //if there is no condition after the removal, delete the entire branch
    if (this._getConditionRule(currentCondition).length <= 1) {
      this._deleteCondition(questionID, conditionItemIndex);
    } else {
      this._updateCondition(questionID, conditionItemIndex, {
        ...currentCondition,
        conditionRule: [
          ...this._getConditionRule(currentCondition).slice(
            0,
            conditionRuleIndex
          ),
          ...this._getConditionRule(currentCondition).slice(
            conditionRuleIndex + 1
          )
        ],
        jointLogics:
          conditionRuleIndex === 0
            ? [
                ...this._getJointLogics(currentCondition).slice(
                  0,
                  conditionRuleIndex
                ),
                ...this._getJointLogics(currentCondition).slice(
                  conditionRuleIndex + 1
                )
              ]
            : [
                ...this._getJointLogics(currentCondition).slice(
                  0,
                  conditionRuleIndex - 1
                ),
                ...this._getJointLogics(currentCondition).slice(
                  conditionRuleIndex
                )
              ]
      });
    }
  };

  _updateConditionRule = (
    questionID: questionIDType,
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    conditionRule: calculatorConditionRuleType
  ) => {
    const condition =
      this._getAllConditionForSpecificQuestion(questionID) || [];

    const specificCondition =
      this._getSpecificConditionForSpecificQuestion(
        questionID,
        conditionItemIndex
      ) || {};

    const conditionRuleList = specificCondition.conditionRule;

    this._updateCondition(questionID, conditionItemIndex, {
      ...specificCondition,
      conditionRule: [
        ...conditionRuleList.slice(0, conditionItemRuleIndex),
        conditionRule,
        ...conditionRuleList.slice(conditionItemRuleIndex + 1)
      ]
    });
  };

  _updateConditionRuleTargetQuestionID = (
    questionID: questionIDType,
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    targetQuestionID: questionIDType
  ) => {
    const specificConditionRule =
      this._getSpecificConditionRuleForSpecificQuestion(
        questionID,
        conditionItemIndex,
        conditionItemRuleIndex
      ) || {};

    this._updateConditionRule(
      questionID,
      conditionItemIndex,
      conditionItemRuleIndex,
      {
        ...specificConditionRule,
        targetQuestionID: targetQuestionID,
        comperator:
          this._getFirstComperator(targetQuestionID) &&
          this._getFirstComperator(targetQuestionID).typeName,
        targetAnswer: this._getFirstChoice(targetQuestionID)
      }
    );
  };

  _updateConditionRuleComperator = (
    questionID: questionIDType,
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    comperatorName: comperatorNameTypes
  ) => {
    const specificConditionRule =
      this._getSpecificConditionRuleForSpecificQuestion(
        questionID,
        conditionItemIndex,
        conditionItemRuleIndex
      ) || {};
    this._updateConditionRule(
      questionID,
      conditionItemIndex,
      conditionItemRuleIndex,
      {
        ...specificConditionRule,
        comperator: comperatorName
      }
    );
  };

  _updateConditionRuleTargetAnswer = (
    questionID: questionIDType,
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    choice: number | string
  ) => {
    const specificConditionRule =
      this._getSpecificConditionRuleForSpecificQuestion(
        questionID,
        conditionItemIndex,
        conditionItemRuleIndex
      ) || {};
    this._updateConditionRule(
      questionID,
      conditionItemIndex,
      conditionItemRuleIndex,
      {
        ...specificConditionRule,
        targetAnswer: choice
      }
    );
  };

  _renderConditionalDetails() {
    return toArray(this.props.questions).map((q, i) => {
      if (this._isJumpLogicSupportedQuestion(q)) {
        return (
          <UICoreBox paddingTop="md">
            <UICoreBox shape="rounded" color="lightGrey" marginTop="xxm">
              <UICoreBox horizontalPadding="sm" verticalMargin="xm">
                <UICoreBox
                  direction="row"
                  justifyContent="between"
                  paddingBottom="xm"
                  paddingTop="xm"
                >
                  <UICoreBox maxWidth="500px">
                    <UICoreText overflow="ellipsis" disableNewLine={true}>
                      Question {i + 1}: {q.title}
                    </UICoreText>
                  </UICoreBox>
                  <UICoreBox
                    onClick={() => {
                      this._addCondition(q.question_id);
                    }}
                  >
                    <UICoreText
                      color="lightBlue"
                      className="CalculatorConfigAddConditionButton"
                    >
                      + Add Condition
                    </UICoreText>
                  </UICoreBox>
                </UICoreBox>
                {this.props.result &&
                  this.props.result.condition &&
                  this.props.result.condition[q.question_id] &&
                  this.props.result.condition[q.question_id].map(
                    (conditionItem, conditionItemIndex) => {
                      return this._renderConditionalDetailListItem(
                        conditionItem,
                        conditionItemIndex,
                        q.question_id
                      );
                    }
                  )}
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
        );
      }
    });
  }

  _renderJointLogics = (
    jointLogic: jointLogicType,
    conditionItemIndex: number,
    conditionItemRuleIndex: number,
    questionID: questionIDType
  ) => {
    if (!jointLogic) return null;
    return (
      <UICoreBox
        marginTop="xm"
        direction="row"
        justifyContent="center"
        marginBottom="xm"
      >
        <UICoreButton
          onClick={() =>
            this._updateJointLogic(
              conditionItemIndex,
              conditionItemRuleIndex,
              "&&",
              questionID
            )
          }
          color={jointLogic === "&&" ? "lightBlue" : "grey"}
        >
          and
        </UICoreButton>
        <UICoreBox marginLeft="xm">
          <UICoreButton
            onClick={() =>
              this._updateJointLogic(
                conditionItemIndex,
                conditionItemRuleIndex,
                "||",
                questionID
              )
            }
            color={jointLogic === "||" ? "lightBlue" : "grey"}
          >
            or
          </UICoreButton>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderTargetAnswerSelector = (
    selectorName,
    conditionRuleItem,
    conditionItemIndex,
    conditionRuleIndex,
    questionID
  ) => {
    switch (selectorName) {
      case targetSelector.choices:
        return (
          <UICoreDropdown
            selectChoice={{
              value: conditionRuleItem.targetAnswer,
              display: conditionRuleItem.targetAnswer
            }}
            choices={this._getAnswerChoices(conditionRuleItem.targetQuestionID)}
            width="100%"
            dropdownWidth={248}
            onChoiceClick={choice => {
              this._updateConditionRuleTargetAnswer(
                questionID,
                conditionItemIndex,
                conditionRuleIndex,
                choice
              );
            }}
          />
        );
      case targetSelector.text:
        return (
          <UICoreInput
            size="xs"
            onChange={(_, value: string) => {
              this._updateConditionRuleTargetAnswer(
                questionID,
                conditionItemIndex,
                conditionRuleIndex,
                value
              );
            }}
            placeholder="type your target answer here..."
            value={conditionRuleItem.targetAnswer}
          />
        );
      case targetSelector.numeric:
        return (
          <UICoreInput
            size="xs"
            onChange={(_, value: string) => {
              this._updateConditionRuleTargetAnswer(
                questionID,
                conditionItemIndex,
                conditionRuleIndex,
                value
              );
            }}
            type="numeric"
            placeholder="type your target answer here..."
            value={conditionRuleItem.targetAnswer}
          />
        );
      default:
        return null;
    }
  };

  _renderConditionalDetailListItemCondition = (
    conditionRule: Array<calculatorConditionRuleType>,
    jointLogics: Array<jointLogicType>,
    conditionItemIndex: number,
    questionID: questionIDType
  ) => {
    return toArray(conditionRule).map(
      (conditionRuleItem, conditionRuleIndex) => {
        return (
          <UICoreBox>
            <UICoreBox direction="row">
              <UICoreBox justifyContent="center" paddingRight="md">
                <UICoreText color="darkGray" size="xs" weight="bold">
                  {`If `}
                </UICoreText>
              </UICoreBox>
              <UICoreBox
                name="Select Question"
                marginTop="xm"
                marginBottom="xm"
                width="600px"
              >
                <UICoreDropdown
                  selectChoice={this._getQuestionChoice(
                    conditionRuleItem.targetQuestionID
                  )}
                  choices={[
                    {
                      display: "Questions",
                      value: "questions",
                      type: UICoreListItemTypeEnum.header
                    },
                    ...this._getAllTargetQuestionsCandidate()
                  ]}
                  width="100%"
                  dropdownWidth={436}
                  onChoiceClick={choice => {
                    this._updateConditionRuleTargetQuestionID(
                      questionID,
                      conditionItemIndex,
                      conditionRuleIndex,
                      choice
                    );
                  }}
                />
              </UICoreBox>
            </UICoreBox>
            <UICoreBox
              name="comperator + target answer"
              direction="row"
              paddingTop="xm"
            >
              <UICoreBox width="20%" marginRight="xm">
                <UICoreDropdown
                  placeholder={conditionRuleItem.comperator}
                  dropdownWidth={200}
                  choices={[
                    {
                      display: "Questions",
                      value: "questions",
                      type: UICoreListItemTypeEnum.header
                    }
                  ]}
                  selectChoice={{
                    value: conditionRuleItem.comperator,
                    display:
                      Comperators[conditionRuleItem.comperator].displayName
                  }}
                  choices={this._getComperatorChoices(
                    this._getQuestionComperatorsByQuestionID(
                      conditionRuleItem.targetQuestionID
                    )
                  )}
                  width="100%"
                  onChoiceClick={choice => {
                    this._updateConditionRuleComperator(
                      questionID,
                      conditionItemIndex,
                      conditionRuleIndex,
                      choice
                    );
                  }}
                />
              </UICoreBox>
              <UICoreBox width="57%">
                {this._renderTargetAnswerSelector(
                  Comperators[conditionRuleItem.comperator] &&
                    Comperators[conditionRuleItem.comperator].targetSelector,
                  conditionRuleItem,
                  conditionItemIndex,
                  conditionRuleIndex,
                  questionID
                )}
              </UICoreBox>
              <UICoreBox marginLeft="xm">
                <UICoreButton
                  onClick={() =>
                    this._addConditionRule(
                      conditionItemIndex,
                      conditionRuleIndex,
                      questionID
                    )
                  }
                  color="lightBlue"
                >
                  +
                </UICoreButton>
              </UICoreBox>

              <UICoreBox marginLeft="xm">
                <UICoreButton
                  onClick={() =>
                    this._removeConditionRule(
                      conditionItemIndex,
                      conditionRuleIndex,
                      questionID
                    )
                  }
                  color="lightBlue"
                >
                  -
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
            {this._renderJointLogics(
              jointLogics[conditionRuleIndex],
              conditionItemIndex,
              conditionRuleIndex,
              questionID
            )}
          </UICoreBox>
        );
      }
    );
  };

  _renderConditionalDetailListItem(
    conditionItem,
    conditionItemIndex,
    questionID
  ) {
    return (
      <UICoreBox
        marginTop="xm"
        marginBottom="xm"
        boxShadow={true}
        shape="rounded"
        marginLeft="sm"
        marginRight="sm"
        padding="sm"
        position="relative"
        color="white"
      >
        {this._renderConditionalDetailListItemCondition(
          conditionItem.conditionRule,
          conditionItem.jointLogics,
          conditionItemIndex,
          questionID
        )}
        <UICoreBox
          onClick={() => this._deleteCondition(questionID, conditionItemIndex)}
          width="20px"
          height="20px"
          shape="circle"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="-8px"
          right="-8px"
          hoverable={true}
          color="white"
        >
          <i style={{ color: "#888888" }} className="ion-close" />
        </UICoreBox>

        <UICoreBox marginTop="md" paddingTop="sm" direction="row">
          <UICoreBox justifyContent="center" paddingRight="md">
            <UICoreText color="darkGray" size="xs" weight="bold">
              Then
            </UICoreText>
          </UICoreBox>
          <UICoreBox width="105px" paddingRight="md">
            <UICoreDropdown
              placeholder="Add"
              width="100%"
              dropdownWidth={200}
              dropdownMaxHeight={150}
              selectChoice={{
                display: conditionItem.operation,
                value: conditionItem.operation
              }}
              choices={conditionOperators}
              onChoiceClick={operation => {
                this._updateCondition(questionID, conditionItemIndex, {
                  ...conditionItem,
                  operation: operation
                });
              }}
            />
          </UICoreBox>
          <UICoreBox width="230px" paddingRight="md">
            <UICoreDropdown
              selectChoice={{
                display: this.props.result.name,
                value: this.props.result.id
              }}
              choices={[
                {
                  display: this.props.result.name,
                  value: this.props.result.id
                }
              ]}
              width="100%"
              dropdownWidth={436}
              dropdownMaxHeight={150}
            />
          </UICoreBox>
          <UICoreBox justifyContent="center" paddingRight="md">
            <UICoreText color="darkGray" size="xs" weight="bold">
              By
            </UICoreText>
          </UICoreBox>
          <UICoreBox width="230px" paddingRight="md">
            <UICoreInput
              size="xs"
              onChange={(_, value: string) => {
                this._updateCondition(questionID, conditionItemIndex, {
                  ...conditionItem,
                  right: value
                });
              }}
              type="numeric"
              placeholder="Type your value here"
              value={conditionItem.right.toString()}
            />
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }

  _handleFormulaInput = (newFormula: string) => {
    let sanitizedString = newFormula
      .replace(/\uFEFF/g, "")
      .replace(/\n/g, "")
      .replace(/\s/g, "");
    let convertedString =
      InputManager.convertStringRep2HumanRedableStringWithNumber(
        sanitizedString
      )
        .replace(/\uFEFF/g, "")
        .replace(/\n/g, "")
        .replace(/\s/g, "");
    if (/^[0-9+\-*/()@^]*$/.test(convertedString)) {
      this.setState({ formula: newFormula, showFormulaError: false });
    } else {
      this.setState({ formula: newFormula, showFormulaError: true });
    }
  };

  _renderFormulaDetails() {
    return (
      <UICoreBox paddingTop="md">
        {" "}
        <UICoreBox
          direction="row"
          justifyContent="center"
          alignItems="center"
          paddingLeft="sm"
          paddingRight="sm"
          position="relative"
        >
          <UICoreBox width="100px">
            <UICoreText>{this.props.result.name} = </UICoreText>
          </UICoreBox>

          <UICoreBox direction="column" width="600px">
            <UICoreTagInput.Editor
              key={this.props.result.name}
              className={
                "CalculatorConfig-Formula-textarea " + this.props.className
              }
              contentEditableClassName="CalculatorConfig-Formula-editable"
              width="100%"
              textStyleProps={{
                weight: "medium",
                children: null,
                size: "sm",
                fontFamily: this.props.styles && this.props.styles.fontFamily
                // hexColor: this.getFontColor()
              }}
              tagTextStyle={{
                children: null,
                size: "sm"
                // hexColor: this.getFontColor()
              }}
              // tagHexColor={this.props.answerSecondaryColor}
              onInputUpdate={this._handleFormulaInput}
              // onInputBlur={() => this.props.saveToBackend()}
              onDropdownOptionClick={() => {}}
              input={this.state.formula ? this.state.formula : ""}
              tagOptionGroups={[
                {
                  header: "Use calculation value from a specific question",
                  tags: getTagInputOptionsForCalculatorFormula(
                    this.props.questions,
                    this.props.questions.length + 1
                  )
                }
              ]}
            />
            <UICoreBox>
              {this.state.showFormulaError && (
                <UICoreText color="red">
                  Your input is invalid, please only use 0,1,...,9, +, -, *, /,
                  (, ), and use @ to recall value from each question. Do not
                  type SPACE or ENTER.
                </UICoreText>
              )}
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
        <UICoreBox paddingTop="md">
          <UICoreText>
            Custom value can be composed arithmetically (+,-,*,/) from
            calculator values of each question you have defined earlier. Type @
            to recall value from questions.{" "}
          </UICoreText>
        </UICoreBox>
      </UICoreBox>
    );
  }

  _renderModalDetail() {
    return (
      <UICoreBox padding="md">
        <UICoreText>
          You have two options for calculation. Select formula based calculation
          where you input an entire arithmetic formula and pull in calculator
          value defined in each question or select conditional to add/subtract
          value based on logic.
        </UICoreText>
        <UICoreSection.Horizontal
          onChange={(_, index) => {
            index === 1
              ? this.setState({ type: "conditional" })
              : this.setState({ type: "formula" });
          }}
          activeSectionIndex={this.state.type === "conditional" ? 1 : 0}
          sections={["Formula", "Conditional"]}
        />

        {this.state.type === "conditional" ? (
          <UICoreBox height="450px" overflowY="scroll">
            {this._renderConditionalDetails()}
          </UICoreBox>
        ) : (
          this._renderFormulaDetails()
        )}
        <UICoreBox
          paddingTop="md"
          alignItems="end"
          justifyContent="end"
          direction="row"
        >
          <UICoreBox paddingRight="sm">
            <UICoreButton
              color="lightBlue"
              key="Save Variable"
              onClick={() => {
                this._saveVariable();
              }}
            >
              Save Variable
            </UICoreButton>
          </UICoreBox>
          <UICoreBox>
            <UICoreButton
              color="red"
              // width="130px"
              key="Delete Variable"
              onClick={() => {
                this.props.deleteResult(this.props.result.name);
                this.setState({ showModal: false });
              }}
            >
              Delete Variable
            </UICoreButton>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }

  render() {
    return [
      this._renderCalculatorResultAndButton(),
      this.state.showModal && (
        <UICoreModal
          size="lg"
          dismissText=""
          onDismiss={_ => {
            // this.props.saveToBackend();
            this._saveVariable();
          }}
          header={
            this.props.result && this.props.result.name
              ? "Calculation for " + this.props.result.name
              : "Calculator Variable"
          }
          body={<UICoreBox>{this._renderModalDetail()}</UICoreBox>}
        />
      )
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    thankyouPages: getThankyouPages(state.Question),
    displayLogic: getThankyouPagesDisplayLogic(state.Question),
    questions: getAllQuestions(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    },
    updateCalculatorCondition: (
      questionID: string,
      calculatorResultID: string,
      updatedCondition: Array<calculatorConditionType>
    ) => {
      dispatch(
        update_calculator_condition(
          questionID,
          calculatorResultID,
          updatedCondition
        )
      );
    }
  };
};

// const mapDispatchToProps = (dispatch, ownProps) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(CalculatorConfigResult));
